"use client";

import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import { useBaseUrl } from "../../../base-url-provider";
import { actionLogin } from "./actions";
import { formSchemaLogin } from "./schema";

export default function LoginForm() {
  const router = useRouter();
  const { baseUrl } = useBaseUrl();
  const form = useForm<z.infer<typeof formSchemaLogin>>({
    resolver: zodResolver(formSchemaLogin),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchemaLogin>) {
    const result = await actionLogin({ values });
    if (!result.success) {
      toast.error(result.error);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <Card className="w-full max-w-4xl shadow-transparent">
        <CardContent className="pb-0">
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="flex flex-col justify-between md:w-2/5 border-b md:border-b-0 md:border-r py-6">
              <div className="flex flex-col space-y-6 md:space-y-12">
                <h1 className="text-xl md:text-2xl font-bold">DatiPersonali.net</h1>
                <h2 className="text-xl md:text-2xl font-semibold">
                  Accedi al pannello admin
                </h2>
              </div>
              <p className="text-sm md:text-md text-gray-400 font-semibold mt-6 md:mt-0">
                Oasi Informatica
              </p>
            </div>
            <div className="flex flex-col space-y-6 md:space-y-8 w-full md:w-3/5 py-6">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-6 md:space-y-8"
                >
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl className="w-full md:w-11/12 h-12 rounded-xl">
                          <Input
                            placeholder="Inserisci il tuo indirizzo email"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl className="w-full md:w-11/12 h-12 rounded-xl">
                          <Input
                            type="password"
                            placeholder="Inserisci la tua password"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button
                    type="submit"
                    disabled={form.formState.isSubmitting}
                    className="bg-[#2C6EC2] w-full md:w-11/12 rounded-xl h-12"
                  >
                    Accedi
                  </Button>
                </form>
              </Form>
              <div className="space-y-2">
                <p className="text-gray-400 text-sm font-semibold">
                  Non riesci ad entrare?
                </p>
                <Link href={`${baseUrl}/auth/forgot-password`} passHref>
                  <Button className="bg-white border border-[#213D60] text-[#213D60] hover:bg-white w-full md:w-11/12 rounded-xl h-12">
                    Recupera la password
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
