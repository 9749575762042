"use client";

import { createContext, useContext, ReactNode } from "react";

interface BaseUrlContextType {
  baseUrl: string;
  baseUrlPublic: string;
}

const BaseUrlContext = createContext<BaseUrlContextType | undefined>(undefined);

interface BaseUrlProviderProps {
  children: ReactNode;
  baseUrl: string;
  baseUrlPublic: string;
}

export function BaseUrlProvider({
  children,
  baseUrl,
  baseUrlPublic,
}: BaseUrlProviderProps) {
  return (
    <BaseUrlContext.Provider value={{ baseUrl, baseUrlPublic }}>
      {children}
    </BaseUrlContext.Provider>
  );
}

export function useBaseUrl() {
  const context = useContext(BaseUrlContext);
  if (!context) {
    throw new Error("useBaseUrl must be used within a BaseUrlProvider");
  }
  return context;
}
